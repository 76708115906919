<template>
    <div v-if="isLoading">
        <div class="text-center text-danger screen-center" style="padding-top: 20%;">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-sm-5">
                <div class="breadcrumb">
                    <h1>Datos Usuario</h1>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="card text-left">
                    <div class="card-header">
                        <div v-if="!activeUpdate" class="col-sm-5">
                            <button class="btn th-custom-color" @click="activeUpdate=true">
                                Modificar
                            </button>
                        </div>
                        <div v-if="activeUpdate" class="col-sm-5">
                            <button class="btn th-custom-color" @click="update">
                                Guardar
                            </button>
                            <button
                                style="margin-left: 10px;"
                                class="btn btn-secondary border border-light" @click="activeUpdate=false">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Nombre Completo:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="nombreCompleto"
                                                    :disabled="!activeUpdate"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Usuario/Mail:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="mail"
                                                    :disabled="disabled"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Contraseña:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input
                                                    type="password"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="password"
                                                    :disabled="!activeUpdate"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">                                        
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Celular:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="celular"
                                                    :disabled="!activeUpdate"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Foto:</label>
                                            </div>
                                            <div class="col-sm-5">
                                                <!-- <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                > -->
                                                <label :class=" urlImage == '' || urlImage == null ? 
                                                'btn btn-secondary col-form-label' : 'btn th-custom-color text-white col-form-label'">
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg,image/png"
                                                        hidden
                                                        id="foto"
                                                        name="foto"
                                                        class="form-control-file"
                                                        ref="file"
                                                        v-on:change="obtenerImagen($event)"
                                                    />Subir
                                                </label>
                                                <label class="col-form-label" 
                                                    @click="verImagen" 
                                                    style="padding-left: 10px; cursor:pointer;">
                                                    Ver:
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-sm-6 pl-5">
                                                <h4>Privilegios</h4>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="row mb-2">
                                                    <div class="col-sm-2 pr-0 text-right">
                                                        <label style="white-space: nowrap;" class="col-form-label">Tipo de Usuario:</label>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <multiselect
                                                            v-model="usuarioSelected"
                                                            :options="usuarios"
                                                            track-by="id"
                                                            label="descripcion"
                                                            placeholder="Seleccionar"
                                                            deselectLabel=""
                                                            selectLabel="Seleccionar"
                                                            :searchable="true"
                                                            :disabled="disabled"
                                                            >
                                                        </multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="col-sm-12 px-5">
                                            <div class="row mb-2">
                                                <table class="table">
                                                    <thead>
                                                        <th></th>
                                                        <th>Nuevo</th>
                                                        <th>Modificar</th>
                                                        <th>Anular</th>
                                                        <th>Listar</th>
                                                        <th>Ver</th>
                                                        <th>Imprimir</th>
                                                    </thead>
                                                    <tbody>
                                                         <tr>
                                                            <th scope="row">Dashboard:</th>
                                                            <td><input type="checkbox" name="nuevo" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="modificar" id="" :disabled="disabled"> </td>
                                                            <td><input type="checkbox" name="anular" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="listar" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="ver" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="imprimir" id="" :disabled="disabled"></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Usuarios:</th>
                                                            <td><input type="checkbox" name="nuevo" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="modificar" id="" :disabled="disabled"> </td>
                                                            <td><input type="checkbox" name="anular" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="listar" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="ver" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="imprimir" id="" :disabled="disabled"></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Facturacion:</th>
                                                            <td><input type="checkbox" name="nuevo" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="modificar" id="" :disabled="disabled"> </td>
                                                            <td><input type="checkbox" name="anular" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="listar" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="ver" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="imprimir" id="" :disabled="disabled"></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Datos Empresa:</th>
                                                            <td><input type="checkbox" name="nuevo" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="modificar" id="" :disabled="disabled"> </td>
                                                            <td><input type="checkbox" name="anular" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="listar" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="ver" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="imprimir" id="" :disabled="disabled"></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Cliente:</th>
                                                            <td><input type="checkbox" name="nuevo" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="modificar" id="" :disabled="disabled"> </td>
                                                            <td><input type="checkbox" name="anular" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="listar" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="ver" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="imprimir" id="" :disabled="disabled"></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sincronizacion SIAT:</th>
                                                            <td><input type="checkbox" name="nuevo" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="modificar" id="" :disabled="disabled"> </td>
                                                            <td><input type="checkbox" name="anular" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="listar" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="ver" id="" :disabled="disabled"></td>
                                                            <td><input type="checkbox" name="imprimir" id="" :disabled="disabled"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get, call, sync } from 'vuex-pathify';
import Multiselect from 'vue-multiselect'


export default {
    name: 'edit',
    components: {
        Multiselect
    },
    created() {
        this.cargarDatosInicial();
    },
    data() {
        return {
            isLoading: false,
            activeUpdate: false,
            disabled: true,
        };
    },
    methods: {
        async cargarDatosInicial() {
            try {
                this.isLoading = true;
                await this.editUser(this.user.id);
            } finally {
                this.isLoading = false;
            }
        },
        async update() {
            this.isLoading = true;
            const UPDATE_OK = await this.updateUser(this.user.id);
            if (UPDATE_OK) {
                this.$router.push({ name: 'usuario-index' });
            }
            this.activeUpdate = false;
            this.isLoading = false;
        },
        obtenerImagen(event) {
            this.archivo = event.target.files[0];
            this.urlImage = URL.createObjectURL(this.archivo);
        },
        verImagen() {
            if (this.urlImage) {
                window.open(this.urlImage);
            }
        },
        ...call('usuario/usuarioUpdate', 
            [
                'editUser',
                'updateUser',
            ]
        ),
    },
    computed: {
        ...get('usuario/usuarioUpdate', ['usuarios','paises','clientes', 'empresas']),
        ...sync('usuario/usuarioUpdate', [
            'nombreCompleto',
            'mail',
            'password',
            'usuarioSelected',
            'paisSelected',
            'clienteSelected',
            'cambiarPassword',
            'celular',
            'estado',
            'archivo',
            'numero',
            'urlImage',
            'empresa',
        ]),
        user: get('main/user'),
    },
    beforeDestroy() {
        this.$store.dispatch('usuario/usuarioUpdate/reset');
    }

}
</script>